exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calcular-hipoteca-tsx": () => import("./../../../src/pages/calcular-hipoteca.tsx" /* webpackChunkName: "component---src-pages-calcular-hipoteca-tsx" */),
  "component---src-pages-contactar-tsx": () => import("./../../../src/pages/contactar.tsx" /* webpackChunkName: "component---src-pages-contactar-tsx" */),
  "component---src-pages-faq-como-puedo-calcular-el-pago-mensual-de-mi-hipoteca-tsx": () => import("./../../../src/pages/faq/como-puedo-calcular-el-pago-mensual-de-mi-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-como-puedo-calcular-el-pago-mensual-de-mi-hipoteca-tsx" */),
  "component---src-pages-faq-cuanto-puedo-pedir-de-hipoteca-tsx": () => import("./../../../src/pages/faq/cuanto-puedo-pedir-de-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-cuanto-puedo-pedir-de-hipoteca-tsx" */),
  "component---src-pages-faq-cuanto-tiempo-dura-una-hipoteca-tsx": () => import("./../../../src/pages/faq/cuanto-tiempo-dura-una-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-cuanto-tiempo-dura-una-hipoteca-tsx" */),
  "component---src-pages-faq-es-obligatorio-tener-seguro-para-obtener-una-hipoteca-tsx": () => import("./../../../src/pages/faq/es-obligatorio-tener-seguro-para-obtener-una-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-es-obligatorio-tener-seguro-para-obtener-una-hipoteca-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-faq-penalizan-los-pagos-anticipados-de-hipoteca-tsx": () => import("./../../../src/pages/faq/penalizan-los-pagos-anticipados-de-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-penalizan-los-pagos-anticipados-de-hipoteca-tsx" */),
  "component---src-pages-faq-que-es-la-tasa-de-interes-de-una-hipoteca-tsx": () => import("./../../../src/pages/faq/que-es-la-tasa-de-interes-de-una-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-que-es-la-tasa-de-interes-de-una-hipoteca-tsx" */),
  "component---src-pages-faq-que-es-una-hipoteca-tsx": () => import("./../../../src/pages/faq/que-es-una-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-que-es-una-hipoteca-tsx" */),
  "component---src-pages-faq-que-gastos-adicionales-debo-considerar-al-pedir-una-hipoteca-tsx": () => import("./../../../src/pages/faq/que-gastos-adicionales-debo-considerar-al-pedir-una-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-que-gastos-adicionales-debo-considerar-al-pedir-una-hipoteca-tsx" */),
  "component---src-pages-faq-que-ocurre-si-tengo-problemas-para-pagar-la-hipoteca-tsx": () => import("./../../../src/pages/faq/que-ocurre-si-tengo-problemas-para-pagar-la-hipoteca.tsx" /* webpackChunkName: "component---src-pages-faq-que-ocurre-si-tengo-problemas-para-pagar-la-hipoteca-tsx" */),
  "component---src-pages-faq-que-tipo-de-hipoteca-es-mejor-para-mi-tsx": () => import("./../../../src/pages/faq/que-tipo-de-hipoteca-es-mejor-para-mi.tsx" /* webpackChunkName: "component---src-pages-faq-que-tipo-de-hipoteca-es-mejor-para-mi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

