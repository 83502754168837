import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import React from "react"
import ReactDOM from "react-dom/client"
import "@fontsource/poppins/500.css" // Medium 500
import "@fontsource/open-sans/300.css" // Light
import "@fontsource/open-sans/400.css" // Normal
import "@fontsource/open-sans/700.css" // Bold
import "@fontsource/open-sans/400-italic.css" // Normal (Italic)
import "./src/styles/global.scss"

// This is key to fix the Hydration errors !!!!
// https://stackoverflow.com/questions/77651939/getting-uncaught-error-minified-react-error-418-on-my-gatsby-production-build
export const replaceHydrateFunction = () => (element, container) =>
  ReactDOM.createRoot(container).render(element)

export const wrapPageElement = ({ element, props }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={`${process.env.RECAPTCHA_V3_SITE_KEY}`}
  >
    <div>{element}</div>
  </GoogleReCaptchaProvider>
)
